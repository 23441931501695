<template>
  <a-card
    :title="titleForTaskCreationCard"
    style="flex-grow: 1; display: flex; flex-direction: column"
    id="edit-task-card"
    :body-style="{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }"
  >
    <a-modal
      id="task-update-confirm-modal"
      :visible="taskUpdateConfirmModal"
      centered
      title="Update task"
      ok-text="Submit"
      :ok-button-props="{ id: 'update-task-ok-btn' }"
      :cancel-button-props="{
        id: 'update-task-cancel-btn',
        style: { marginLeft: 'auto' },
      }"
      @ok="handleCheckText"
      @cancel="resetLocalState"
    >
      <template #closeIcon>
        <close-outlined id="task-update-close-btn" />
      </template>
      <p>Are you sure you want to update the Standard Operating Procedure?</p>
      <p>
        This will cause all existing labels to be updated according to the new
        Procedure and the translation to be lost.
      </p>
      <p>
        Please type '<i class="text-danger">update task</i>' in the following
        box and press submit.
      </p>
      <div class="d-flex flex-column">
        <a-input
          id="update-task-confirm-input"
          v-model:value="taskUpdateMsg"
          class="w-50"
        />
        <small v-if="isError" class="text-danger">Please type correctly!</small>
      </div>
    </a-modal>
    <template #extra>
      <div class="merge-info" v-if="currentEditMode === editModes.merge">
        <a-tooltip title="User Instruction">
          <InfoCircleOutlined color="red" style="margin-top: 4px" />
        </a-tooltip>
        <a-typography-text type="danger">
          <span>
            Note: Right click on sub-step to merge into other sub-step</span
          >
        </a-typography-text>
      </div>

      <a-popover v-if="currentEditMode === editModes.addDelete">
        <template #content>
          <p><span class="color-box bg-primary" /> : Represents New Step(s).</p>
        </template>
        <a-button v-if="selectedTask" type="primary" size="small">
          <template #icon>
            <InfoCircleOutlined />
          </template>
        </a-button>
      </a-popover>
    </template>
    <template #actions>
      <div class="d-flex">
        <a-space class="ml-auto">
          <a-button
            v-if="!!newTask || processes.length > 0"
            id="reset-process-btn"
            :disabled="isAddingTask"
            @click="$emit('onCancelEditTask')"
          >
            Cancel
          </a-button>
          <a-button
            v-if="isTaskEdit && isNotAddDeletePhase"
            type="primary"
            @click="handleEditPreviousPhaseChange"
          >
            Back
          </a-button>

          <a-button
            v-if="isTaskEdit && currentEditMode !== editModes.merge"
            type="primary"
            @click="handleEditNextPhaseChange"
          >
            Next
          </a-button>

          <a-button
            v-if="showAddUpdateButton && !isUpdateTaskFromAdmin"
            id="submit-task-btn"
            type="primary"
            :loading="isAddingTask"
            @click="upsertTask"
          >
            {{ `${isTaskEdit ? 'Update' : 'Add'} Operation` }}
          </a-button>

          <a-popconfirm
            cancel-text="No"
            ok-text="Yes"
            :title="'Are you sure you want to update the Standard Operating Procedure?'"
            @confirm="$emit('onConfirmUpdateTask')"
          >
            <template #icon>
              <QuestionCircleOutlined class="text-warning" />
            </template>
            <a-button
              v-if="showAddUpdateButton && isUpdateTaskFromAdmin"
              type="primary"
              :loading="isAddingTask"
            >
              Update Operation
            </a-button>
          </a-popconfirm>
        </a-space>
      </div>
    </template>

    <a-input
      id="add-task-name-input"
      style="margin-bottom: 1em"
      :value="newTask"
      placeholder="Operation Name"
      :disabled="isTaskEdit"
      @change="(e) => setNewTask(e.target.value)"
    />
    <div class="processes-container">
      <process-component
        v-for="(process, processIndex) in processes"
        :key="processIndex"
        :process="process.name"
        :process-index="processIndex"
      />
      <div class="d-flex justify-content-end mt-2">
        <a-button
          v-if="currentEditMode === editModes.addDelete || !isTaskEdit"
          id="add-process-btn"
          type="primary"
          class="mb-3"
          @click="addProcess"
        >
          Add Process
          <template #icon>
            <plus-circle-outlined />
          </template>
        </a-button>
      </div>
    </div>
  </a-card>
</template>
<script>
import ProcessComponent from './Process.vue';
import { mapGetters, mapActions } from 'vuex';
import {
  PlusCircleOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons-vue';
import {
  modes,
  nextPrevButtonMap,
  taskCreationCardTitle,
} from 'src/config/task-edit-modes-config.js';
import { setOldTaskDetails } from '@/utils/task.js';

export default {
  components: {
    ProcessComponent,
    PlusCircleOutlined,
    CloseOutlined,
    QuestionCircleOutlined,
    InfoCircleOutlined,
  },
  inject: ['toast'],
  emits: ['onCancelEditTask', 'onConfirmUpdateTask'],
  setup: () => ({ nextPrevButtonMap, modes }),
  data() {
    return {
      taskUpdateMsg: '',
      oldTaskDetails: '',
      isError: false,
    };
  },

  computed: {
    ...mapGetters([
      'newTask',
      'editModes',
      'processes',
      'editModes',
      'isTaskEdit',
      'taskDetails',
      'organization',
      'selectedTask',
      'isAddingTask',
      'currentEditMode',
      'updatedJsonPayload',
      'taskUpdateConfirmModal',
      'isUpdateTaskFromAdmin',
    ]),

    titleForTaskCreationCard() {
      if (!this.isTaskEdit) return 'Add New Operation';
      return 'Edit Operation ' + taskCreationCardTitle[this.currentEditMode] || '';
    },

    isDisabled() {
      return (
        this.newTask &&
        this.processes.length > 0 &&
        this.processes[0].name &&
        this.processes[0].steps.length > 0 &&
        this.processes[0].steps[0].substeps[0]
      );
    },

    showAddUpdateButton() {
      if (!this.isTaskEdit) return true;
      return this.currentEditMode == modes.merge;
    },

    isNotAddDeletePhase() {
      return (
        this.currentEditMode === modes.rename ||
        this.currentEditMode === modes.merge
      );
    },
  },

  watch: {
    isTaskEdit(value) {
      if (!value) return;
      this.oldTaskDetails = setOldTaskDetails(this.taskDetails);
    },
  },

  methods: {
    ...mapActions([
      'addProcess',
      'upsertTask',
      'updateTask',
      'setNewTask',
      'setProcesses',
      'moveToRenamePhaseFromAddDelete',
      'moveToRenamePhaseFromMerge',
      // 'moveToRenamePhase',
      'setCurrentEditMode',
      'moveToAddDeletePhase',
      'moveToMergeStepPhase',
      'setUpdatedJsonPayload',
      'setTaskUpdateConfirmModal',
    ]),

    async handleCheckText() {
      if (this.taskUpdateMsg?.trim() !== 'update task') {
        this.isError = true;
        return;
      }
      this.resetLocalState();
      await this.updateTask(this.oldTaskDetails);
      this.emitter.emit('startLRTInterval');
    },

    resetLocalState() {
      this.setTaskUpdateConfirmModal(false);
      this.isError = false;
      this.taskUpdateMsg = '';
    },

    handleEditPreviousPhaseChange() {
      const temp = {
        [modes.merge]: this.moveToRenamePhaseFromMerge,
        [modes.rename]: this.moveToAddDeletePhase,
      };
      temp[this.currentEditMode] && temp[this.currentEditMode]();
    },

    handleEditNextPhaseChange() {
      const temp = {
        [modes.addDelete]: this.moveToRenamePhaseFromAddDelete,
        [modes.rename]: this.moveToMergeStepPhase,
      };
      temp[this.currentEditMode] && temp[this.currentEditMode]();
    },
  },
};
</script>
<style>
.processes-container {
  padding: 0.5em;
  overflow-y: auto;
  height: 1px;
  flex-grow: 1;
}

#edit-task-card .ant-card-extra .merge-info {
  width: 250px !important;
  display: flex;
  align-items: flex-start;
  gap: 6px;
}
#edit-task-card .ant-card-extra .merge-info svg {
  fill: #ff4d4f;
}
</style>
