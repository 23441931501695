<template>
  <a-card
    id="process-box"
    hoverable
    class="process-container"
    :body-style="{ padding: '0.5em' }"
  >
    <span style="display: block; margin-bottom: 8px"
      >Valid Characters include A-z 0-9, -</span
    >

    <div class="d-flex">
      <a-input
        v-if="currentEditMode === editModes.rename || !isTaskEdit"
        id="process-name-input"
        class="mr-2"
        :value="process"
        placeholder="Process Name"
        @input="handleProcessChange"
      />

      <label v-else :class="getLabelClass">
        {{ process || 'Process Name ' }}
      </label>
      <a-button
        v-if="currentEditMode === editModes.addDelete || !isTaskEdit"
        id="process-cross-btn"
        type="danger"
        class="ml-auto"
        @click="removeProcess({ processIndex })"
      >
        <template #icon>
          <close-circle-outlined />
        </template>
      </a-button>
    </div>

    <div class="steps-container">
      <merge-steps
        v-if="currentEditMode === editModes.merge"
        :process-steps="newSteps(processIndex)"
        :process-index="processIndex"
      />
      <step-component
        v-for="(step, stepIndex) in newSteps(processIndex)"
        v-else
        :key="stepIndex"
        :step-index="stepIndex"
        :step="step.name"
        :process-index="processIndex"
      />
      <div class="d-flex justify-content-end">
        <a-button
          v-if="currentEditMode === editModes.addDelete || !isTaskEdit"
          id="add-step-btn"
          type="primary"
          @click="addStep({ processIndex })"
        >
          <template #icon>
            <plus-circle-outlined />
          </template>
          Add Step
        </a-button>
      </div>
    </div>
  </a-card>
</template>
<script>
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons-vue';
import { mapActions, mapGetters } from 'vuex';
import MergeSteps from './MergeSteps.vue';
import StepComponent from './Step.vue';

export default {
  components: {
    StepComponent,
    MergeSteps,
    CloseCircleOutlined,
    PlusCircleOutlined,
  },
  props: {
    processIndex: {
      type: Number,
      default: 1,
    },
    process: {
      type: String,
      default: '',
    },
  },
  emits: [],
  data() {
    return {
      writingMode: false,
      inputFlag: false,
      window: window,
    };
  },

  computed: {
    ...mapGetters(['newSteps', 'currentEditMode', 'editModes', 'isTaskEdit']),
  },

  created() {
    this.inputFlag = !!localStorage.getItem('inputFlag');
  },

  methods: {
    ...mapActions(['updateProcess', 'removeProcess', 'addStep']),
    handleProcessChange(e) {
      const { value: process } = e.target;
      const { processIndex } = this;

      const sanitizedInput = process.replace(/[^a-zA-Z0-9 \-]/g, '');

      this.updateProcess({
        processIndex,
        process: sanitizedInput,
      });

      e.target.value = sanitizedInput;
    },
  },
};
</script>
<style scoped>
.process-container {
  /* height: 50vh; */
  /* margin: 0.5em 0; */
  padding: 0.5em;
  margin-bottom: 1em;
}

.steps-container {
  margin-top: 0.5em;
  /* overflow-y: auto; */
  /* height: 40vh; */
  /* padding: 0.5em; */
}

.card {
  /* overflow-y: auto; */
  padding: 2px;
}
.form-control {
  width: 90%;
  height: 30.44px;
}

.btn-danger {
  height: 30.44px;
}

.icon {
  cursor: pointer;
  color: blue;
}

.icon-disabled {
  cursor: auto;
  color: lightgray;
}
</style>
